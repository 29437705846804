var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"form"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Residence number'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":"Número residencia","disabled":"","type":"number"},model:{value:(_vm.objResidencyDocument.residency_id),callback:function ($$v) {_vm.$set(_vm.objResidencyDocument, "residency_id", $$v)},expression:"objResidencyDocument.residency_id"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Type of document'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' cédula'},model:{value:(_vm.objResidencyDocument.document_description),callback:function ($$v) {_vm.$set(_vm.objResidencyDocument, "document_description", $$v)},expression:"objResidencyDocument.document_description"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Date'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objResidencyDocument.date_presented),callback:function ($$v) {_vm.$set(_vm.objResidencyDocument, "date_presented", $$v)},expression:"objResidencyDocument.date_presented"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Document status'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objResidencyDocument.document_status),callback:function ($$v) {_vm.$set(_vm.objResidencyDocument, "document_status", $$v)},expression:"objResidencyDocument.document_status"}},_vm._l((_vm.listDocumentStatus),function(option){return _c('option',{key:option.id,domProps:{"value":option.name}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Type of document') + ' id',"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-autocomplete',{attrs:{"placeholder":_vm.$t('Ex:') + ' Foto...',"keep-first":true,"open-on-focus":true,"field":"document_type","data":_vm.filteredDataObj,"clearable":true},on:{"select":function (option) { return (_vm.selected = option); },"input":function($event){return _vm.fileDocument()}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_vm._v(" "+_vm._s(props.option.document_type)+" ")])])]}}],null,true),model:{value:(_vm.objResidencyDocument.document_type_id),callback:function ($$v) {_vm.$set(_vm.objResidencyDocument, "document_type_id", $$v)},expression:"objResidencyDocument.document_type_id"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('div',{staticClass:"is-flex is-flex-direction-column is-align-items-start"},[_c('label',{staticClass:"label is-align-self-flex-start",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('Document')))]),_c('b-field',{staticClass:"file",class:{ 'has-name': !!_vm.objResidencyDocument.upload }},[_c('b-upload',{staticClass:"file-label",on:{"input":function($event){return _vm.fileChange()}},model:{value:(_vm.objResidencyDocument.upload),callback:function ($$v) {_vm.$set(_vm.objResidencyDocument, "upload", $$v)},expression:"objResidencyDocument.upload"}},[_c('span',{staticClass:"file-cta"},[_c('b-icon',{staticClass:"file-icon",attrs:{"icon":"upload"}}),_c('span',{staticClass:"file-label"},[_vm._v(_vm._s(_vm.$t('Upload document')))])],1),(_vm.objResidencyDocument.upload)?_c('span',{staticClass:"file-name"},[_vm._v(" "+_vm._s(_vm.objResidencyDocument.upload.name)+" ")]):_vm._e()])],1)],1)])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-9"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Comment'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea","placeholder":_vm.$t('Ex:') + ' Aplicación devolución de garantía...'},model:{value:(_vm.objResidencyDocument.comment),callback:function ($$v) {_vm.$set(_vm.objResidencyDocument, "comment", $$v)},expression:"objResidencyDocument.comment"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"button btn-save",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v("Guardar")]),_c('b-button',{staticClass:"button btn-cancel",on:{"click":_vm.close}},[_vm._v("Cancelar")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }